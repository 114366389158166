/*
 * @Author: genwai
 * @Date: 2020-05-19 14:54:36
 * @LastEditTime: 2020-05-23 11:58:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\dialog\addDialogClass.js
 */

class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.title = ''
      this.subtitle = ''
      this.detail = ''
      this.picturePath = ''
      this.pictureUrl = ''
      // 无需传给后端
      this.filesPicturesPath = []
    } else if (type === 'rule') {
      this.title = [
        {
          required: true,
          message: '请输入标题',
          trigger: 'change',
        },
      ]
      this.subtitle = [
        {
          required: true,
          message: '请输入副标题',
          trigger: 'change',
        },
      ]
      this.detail = [
        {
          required: true,
          message: '请输入资讯内容',
          trigger: 'change',
        },
      ]
      this.picturePath = [
        {
          required: true,
          message: '请选择图片',
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
